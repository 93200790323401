import React from 'react';
import './Pagination.scss';
import { Link } from "gatsby";

const Pagination = ({ href, selectPage, cards, recordsPerPage, currentPage }) => {
    const nPages = Math.ceil(cards.length / recordsPerPage);
    const pagesArr = [...Array(nPages).keys()].map(x => ++x);

    const selectBtn = (event, page) => {
        selectPage(page);
        event.currentTarget.classList.add('selected');
    }

    return(
        <div className="pagination new-component">
            {
                pagesArr.map((page) => (
                    <Link href={href} key={page}>
                        <div 
                            className={`paginationNumbers clickable ${(page === currentPage) && 'selected'}`} 
                            onClick={(event) => selectBtn(event, page)}
                            >
                            {page}
                        </div>
                    </Link>
                ))
            }
        </div>
    );
}

export default Pagination;