import React from "react";
import Icon from '@mdi/react';
import { mdiFoodVariant, mdiTimerOutline } from '@mdi/js';
import './basicCard.scss';
import { useTranslation, Link, useI18next } from 'gatsby-plugin-react-i18next';

const BasicCard = ({
  title,
  path,
  image,
  mobileImage,
  classNames,
  mealType,
  prepTime,
  isSimpleCard
  }) => {  
  const { language } = useI18next();
  const { t } = useTranslation();

  return (
        <div className={`basic-card ${classNames}`}   >
          <Link
            title={t(title)}
            to={`/recipes/${path}`}
            language={language}
          >
            <img
              className="mobile-image"
              src={mobileImage}
              alt={t(title)}
              loading="lazy"
            />
            <img
              className="desktop-image"
              src={image}
              alt={t(title)}
              loading="lazy"
            />
            <div
              className={`basic-card__info w-100 d-flex flex-column justify-content-center ${isSimpleCard && 'basic-card__simple'}`}
            >
              <div
                className={`title`}
              >
                {t(title)}
              </div>
              {
                (!isSimpleCard) &&
                <div className="valuesSection">
                  <div className="values">
                    <Icon path={mdiTimerOutline}
                      title="timer"
                      size={.7}
                      horizontal
                      color="#0f4735"/> {t(prepTime)} {t("recipeDetail.panel2Mins")}</div> 
                  <div className="values">|</div>
                  <div className="values" >
                    <Icon path={mdiFoodVariant}
                      title="food"
                      size={.7}
                      horizontal
                      color="#0f4735"/> {t(mealType)}</div>
                </div>
              }
            </div>
          </Link>
        </div>
  )
}

export default BasicCard;