import React, { useState, useRef, useEffect } from "react";
import Layout from "../components/Layout";
import "../scss/products.scss";
import "../scss/recipes.scss";
import Helmet from "react-helmet";
import Card from "../components/Card/BasicCard";
import Pagination from "../components/Pagination/Pagination";
import {recipeCards} from "../components/Card/recipeCards";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
  } from 'reactstrap';
import { mdiMagnify } from '@mdi/js';
import Icon from '@mdi/react';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';
import { graphql } from "gatsby";

const Recipes = ({ location }) => {
    const { t } = useTranslation();
    const { i18n } = useI18next();

    const getTypeParam = () => {
        let params = new URLSearchParams(location.search);
        let type = params.get('type');
    
        if (type && (type === 'snack' || type === 'meal')) {    
            return type.charAt(0).toUpperCase() + type.slice(1);
        } else {
            return 'View All';
        }
    }

    let type = getTypeParam();

    const [category, setCategory] = useState(type);

    useEffect(() => {
        changeCategory(type);
    }, [type]);

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [cards, setCards] = useState(recipeCards);

    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(9);
    
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = cards.slice(indexOfFirstRecord, indexOfLastRecord);

    const searchBox = useRef('');
    const [searchBoxState, setSearchBoxState] = useState(false);
    

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const changeCategory = (type) => {
        if (type === "Meal") {
            setCategory(t("recipeLanding.filterOption2"));
        } else if (type === "Snack") {
            setCategory(t("recipeLanding.filterOption3"));
        } else {
            setCategory(t("recipeLanding.filterOption"));
        }

        filterCards(type);
    };

    const filterCards = (type) => {
        let result = [];

        if (type !== 'View All') {
            result = recipeCards.filter(card => card.categoryType === type);
        } else {
            result = recipeCards;
        }

        searchBox.current.value = '';
        setSearchBoxState(false);

        setCurrentPage(1);
        setCards(result);
    };

    const filterCardsByInput = (event) => {
        const { value } = event.target;

        if (value.length > 0) {
            setSearchBoxState(true);

            if (value.length >= 3) {
                setCategory(t("recipeLanding.filterOption"));

                let inputCards = recipeCards.filter(card => {
                    let { title } = card;
                    title = t(title);
                    title = title.toLocaleLowerCase();
                    
                    return title.includes(value.toLocaleLowerCase());
                });

                setCurrentPage(1);
                setCards(inputCards);
            }
        } else {
            setCurrentPage(1);
            setCards(recipeCards);
            setSearchBoxState(false);
        }
    };
     const description4= "recipeLanding.description4"
    return (
        <Layout title="Plant-based recipes that are made 100% from plants" description="FIRST PRIDE plant-based recipes, made 100% from plants. A healthier option that tastes just like meat | FIRST PRIDE SG">
            <Helmet> 
                <link rel="alternate" href="https://www.firstpride.sg/products" hreflang="en-sg" />
                <link rel="alternate" href="https://www.firstpridethailand.com/products/" hreflang="en-th" />
            </Helmet>
                <div className="recipes">
                    <div className="recipeBanner">
                        <div className="productsBannerContent" style={{ whiteSpace: "pre-wrap" }}>
                            <h1 id="top">{t("recipeLanding.title")}</h1>
                            <h5 className="p-regular d-none d-md-block">
                                
                                {t("recipeLanding.description")}
                                <b>{t("recipeLanding.description2")}</b>
                                {/* <br /> */}
                                {t("recipeLanding.description3")}
                                { 
                                    t(description4) !== '' && <b>{t(description4)}</b>
                                }
                            </h5>
                            <h5 className="p-regular d-block d-md-none">
                                {i18n.language !== "th" ? t("recipeLanding.descriptionMobile") : t("recipeLanding.description")}
                                <b>{t("recipeLanding.description2")}</b>
                                {i18n.language ==="th" && <br/>}
                                {t("recipeLanding.description3")}
                                { 
                                    t(description4) !== '' && <b>{t(description4)}</b>
                                }
                            </h5>
                        </div>
                    </div>
                    <div className="productsContentWrapper" style={{ whiteSpace: "pre-wrap" }}>
                        <h2>{t("recipeLanding.QuickBrowse")}</h2>
                        <div className="filtersGroup">
                            <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="down" className="dropdownSelect firstFilter">
                                <DropdownToggle caret>{category}</DropdownToggle>
                                <DropdownMenu>
                                <DropdownItem onClick={() => {changeCategory('View All')}}>{t("recipeLanding.filterOption")}</DropdownItem>
                                <DropdownItem onClick={() => {changeCategory('Meal')}}>{t("recipeLanding.filterOption2")}</DropdownItem>
                                <DropdownItem onClick={() => {changeCategory('Snack')}}>{t("recipeLanding.filterOption3")}</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            <div className="categorySelect">
                                <div className={`category ${category === t("recipeLanding.filterOption") && 'selected'} clickable`} onClick={() => {changeCategory('View All')}}>{t("recipeLanding.filterOption")}</div>
                                <div className="category"></div>
                                <div className={`category ${category === t("recipeLanding.filterOption2") && 'selected'} clickable`} onClick={() => {changeCategory('Meal')}}>{t("recipeLanding.filterOption2")}</div>
                                <div className="category"></div>
                                <div className={`category ${category === t("recipeLanding.filterOption3") && 'selected'} clickable`} onClick={() => {changeCategory('Snack')}}>{t("recipeLanding.filterOption3")}</div>
                            </div>
                            <div className={`wrapper lastFilter ${searchBoxState ? 'active' : ''}`}>
                                <Icon path={mdiMagnify}
                                    title="User Profile"
                                    size={1}
                                    horizontal
                                    vertical
                                    rotate={180}
                                    color="grey"
                                    className="icon"/>
                                
                                <input ref={searchBox} placeholder={t("recipeLanding.searchBoxPlaceholder")} type="text" onChange={filterCardsByInput}/>
                            </div>
                        </div>
                        
                        <div className={`${currentRecords.length === 0 ? 'noCards' : 'cardsGrid'}`}>
                            {currentRecords.length === 0 ?
                                <h1>{t("recipeLanding.noResultsFound")}</h1>
                                :
                                currentRecords.map((item, i)=>(
                                    <Card  
                                    key={item.title}
                                    title={item.title}
                                    classNames=""
                                    path={item.path}
                                    image={item.desktopImage}
                                    mobileImage={item.mobileImage}
                                    mealType={item.typeOfMeal}
                                    prepTime={item.preparationTime}
                                    isSimpleCard={false}
                                    />
                                    )
                                ) 
                            }
                        </div>

                        <Pagination 
                            href="#top" 
                            selectPage={(page) => setCurrentPage(page)} 
                            cards={cards} 
                            recordsPerPage={recordsPerPage}
                            currentPage={currentPage} />
                    </div>
                </div>
        </Layout>
    );
};

export default Recipes;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "navbar", "footer", "recipes"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;