import LarbLettuce from "../../img/recipes/1_TH_LarbLettuce.webp";
import LarbLettuceMobile from "../../img/recipes/1_TH_LarbLettuce_800x800.webp";
import spagettiBolognese from "../../img/recipes/2_TH_SpaghettiBolognese.webp";
import spagettiBologneseMobile from "../../img/recipes/2_TH_Spaghettibolognese_800x800.webp";
import Krapow from "../../img/recipes/3_TH_PadKrapow.webp";
import KrapowMobile from "../../img/recipes/3_TH_PadKrapow_800x800.webp";
import Teriyaki from "../../img/recipes/4_TH_GingerTeriyakiBowl.webp";
import TeriyakiMobile from "../../img/recipes/4_TH_GingerTeriyakiBowl_800x800.webp";
import SpringRolls from "../../img/recipes/5_TH_SpringRolls.webp";
import SpringRollsMobile from "../../img/recipes/5_TH_SpringRolls_800x800.webp";
import KatsuSando from "../../img/recipes/6_TH_CrispyNuggetKatsu.webp";
import KatsuSandoMobile from "../../img/recipes/6_TH_KatsuSando_800x800.webp";
import HerbSalad from "../../img/recipes/7_TH_HerbSalad.webp";
import HerbSaladMobile from "../../img/recipes/7_TH_HerbSalad_800x800.webp";
import KhaoJeeCanapes from "../../img/recipes/8_TH_KhaoJeeCanapes.webp";
import KhaoJeeCanapesMobile from "../../img/recipes/8_TH_KhaoJeeCanapes_800x800.webp";
import SpicyPattiesMangoSalad from "../../img/recipes/9_TH_TodMun.webp";
import SpicyPattiesMangoSaladMobile from "../../img/recipes/9_TH_TodMun_800x800.webp";
import ChickenWaffles from "../../img/recipes/10_TH_No-ChickenWaffles.webp";
import ChickenWafflesMobile from "../../img/recipes/10_TH_No-ChickenWaffles_800x800.webp";

export const recipeCards = [
    {
        id:0,
        title:"recipeLanding.recipeTitles.recipeTitle1",
        path:"spicy-larb-lettuce-cups/",
        preparationTime:"recipeDescription1.cookTime",
        typeOfMeal:"recipeDescription1.type",
        categoryType: 'Snack',
        desktopImage:LarbLettuce,
        mobileImage:LarbLettuceMobile

    },
    {
        id:1,
        title:"recipeLanding.recipeTitles.recipeTitle2",
        path:"plant-based-bolognese/",
        preparationTime:"recipeDescription2.cookTime",
        typeOfMeal:"recipeDescription2.type",
        categoryType: 'Meal',
        desktopImage:spagettiBolognese,
        mobileImage:spagettiBologneseMobile
    },
    {
        id:2,
        title:"recipeLanding.recipeTitles.recipeTitle3",
        path:"plant-based-krapow/",
        preparationTime:"recipeDescription3.cookTime",
        typeOfMeal:"recipeDescription3.type",
        categoryType: 'Meal',
        desktopImage:Krapow,
        mobileImage:KrapowMobile
    },
    {
        id:3,
        title:"recipeLanding.recipeTitles.recipeTitle4",
        path:"plant-based-teriyaki-udon/",
        preparationTime:"recipeDescription4.cookTime",
        typeOfMeal:"recipeDescription4.type",
        categoryType: 'Meal',
        desktopImage:Teriyaki,
        mobileImage:TeriyakiMobile
    },
    {
        id:4,
        title:"recipeLanding.recipeTitles.recipeTitle5",
        path:"plant-based-spring-rolls/",
        preparationTime:"recipeDescription5.cookTime",
        typeOfMeal:"recipeDescription5.type",
        categoryType: 'Snack',
        desktopImage:SpringRolls,
        mobileImage:SpringRollsMobile
    },
    {
        id:5,
        title:"recipeLanding.recipeTitles.recipeTitle6",
        path:"plant-based-katsu-sando/",
        preparationTime:"recipeDescription6.cookTime",
        typeOfMeal:"recipeDescription6.type",
        categoryType: 'Snack',
        desktopImage:KatsuSando,
        mobileImage:KatsuSandoMobile
    },
    {
        id:6,
        title:"recipeLanding.recipeTitles.recipeTitle7",
        path:"larb-bites-salad/",
        preparationTime:"recipeDescription7.cookTime",
        typeOfMeal:"recipeDescription7.type",
        categoryType: 'Meal',
        desktopImage:HerbSalad,
        mobileImage:HerbSaladMobile
    },
    {
        id:7,
        title:"recipeLanding.recipeTitles.recipeTitle8",
        path:"khaojee-larbs-canapes/",
        preparationTime:"recipeDescription8.cookTime",
        typeOfMeal:"recipeDescription8.type",
        categoryType: 'Snack',
        desktopImage:KhaoJeeCanapes,
        mobileImage:KhaoJeeCanapesMobile
    },
    {
        id:8,
        title:"recipeLanding.recipeTitles.recipeTitle9",
        path:"plant-based-thai-spicy-patties-with-mango-salad/",
        preparationTime:"recipeDescription9.cookTime",
        typeOfMeal:"recipeDescription9.type",
        categoryType: 'Meal',
        desktopImage:SpicyPattiesMangoSalad,
        mobileImage:SpicyPattiesMangoSaladMobile
    },
    {
        id:9,
        title:"recipeLanding.recipeTitles.recipeTitle10",
        path:"honey-sriracha-no-chicken-waffles/",
        preparationTime:"recipeDescription10.cookTime",
        typeOfMeal:"recipeDescription10.type",
        categoryType: 'Meal',
        desktopImage:ChickenWaffles,
        mobileImage:ChickenWafflesMobile
    }
];